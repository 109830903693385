<template>
    <div class="home">        
        <main id="main">

            <!-- ======= Breadcrumbs ======= -->
            <section class="breadcrumbs">
                <div class="container">

                    <div class="d-flex justify-content-between align-items-center">
                    <h2>Syarat & Ketentuan</h2>
                    <ol>
                        <li><a href="/">Home</a></li>
                        <li>Syarat & Ketentuan</li>
                    </ol>
                    </div>

                </div>
            </section><!-- End Breadcrumbs -->

            <!-- ======= Portfolio Section ======= -->
            <section class="pricing">
                <div class="container">

                <div class="section-title">
                    <h2>Syarat & Ketentuan</h2>         
                    <p>Mohon di baca terlebih dahulu syarat & ketentuan yg berlaku.</p>           
                </div>

                <div class="col-md-12">
                    <ul id="features-filter" class="nav nav-pills mb-2 justify-content-center" >
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="pill" href="#sewabox" role="tab" >Sewa Box Seserahan</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="pill" href="#invitation" role="tab">Undangan Digital</a>
                    </li>
                    </ul>
                </div>

                <div class="row justify-content-center">
                    <div class="tab-content" >
                        <!-- UNDANGAN SECTION -->
                        <div class="tab-pane fade show active" id="sewabox" role="tabpanel" aria-labelledby="sewabox-tab">
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-md-6 mt-2">
                                    <div class="box featured">
                                        <img v-img:foto src="assets/img/snk1.jpeg" class="img-fluid" alt="" style="border-radius: 5%;">
                                    </div> 
                                </div> 
                                <div class="col-lg-6 col-md-6 mt-2">
                                    <div class="box featured">
                                        <img v-img:foto src="assets/img/snk2.jpeg" class="img-fluid" alt="" style="border-radius: 5%;">
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        <!-- ENGAGEMENT SECTION -->
                        <div class="tab-pane fade" id="invitation" role="tabpanel" aria-labelledby="invitation-tab">
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-md-6 mt-2">
                                    <div class="box featured">
                                        <ul style="text-align: left;">
                                            <li>1. Free uji coba <b>tanpa perlu DP</b></li>
                                            <li>2. Free booking <b>tanpa perlu tanggal pasti</b></li>
                                            <li>3. Free ganti template (<b>apabila belum melakukan pembayaran</b>)</li>
                                            <li>4. Free reschedul acara </li>
                                            <li>5. Free revisi minor (Typo, salah input lokasi acara, dan kesalahan lain yang berhubungan textual serta terkait informasi acara) </li>
                                            <li>6. Free revisi major (Ubah foto yang diupload, ubah latar musik, responsive view, dan sebagainya terkait konten undangan) </li>
                                            <li>7. Semua paket tidak include edit foto. Jadi pastikan foto sudah benar dan sesuai format kami.</li>
                                            <li>8. Pastikan semua data terisi dengan benar ketika mengisi form data. (Termasuk besar dan kecilnya huruf)</li>
                                            <li>9. Pengerjaan undangan maksimal <b>4 x 24 Jam</b> kerja (diluar hari libur dan hari besar nasional). Proses pengerjaan dimulai setelah submit Google Form dan Customer sudah melakukan konfimasi pada admin.</li>
                                            <li>10. Template yang sudah dipilih tidak dapat diubah warna, jenis font, dan tema.</li>
                                            <li>11. Jika sudah generate nama tamu, tidak bisa mengganti dengan template lain.</li>
                                            <li>12. Masa aktif selama 3 bulan terhitung H+0 acara.</li>
                                            <li>13. <b>Pembayaran undangan digital</b> dilakukan sebelum generate nama tamu. Tujuan pembayaran akan diinformasikan melalui Whatsapp Admin</li>
                                            <li>14. Feature yang sudah termasuk paket bisa dihilangkan jika tidak diperlukan, namun tidak mengurangi harga.</li>
                                            <li>15. Data yang masuk akan menjadi katalog kami dan portfolio kami. Jika anda keberatan, mohon konfirmasi pada Google Form.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>

                </div>
            </section>
            <!-- End Portfolio Section -->

        </main><!-- End #main -->
    </div>
    
</template>

<script>
export default {
  props: ['templates'],
  data() {
    return{

    }
  },
}
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .nav-link:hover,
.nav-pills .show > .nav-link {
  background: #BCA37F;
  color: #ffffff;
}

.nav-pills .nav-link {
  cursor: pointer;
  display: inline-block;
  padding: 8px 18px 10px 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  margin: 0 3px 10px 3px;
  transition: all ease-in-out 0.3s;
  background: #f4f5f8;
  border-radius: 50px;
}
</style>