<template>
    <div class="sitemap">
      <iframe :src="url" frameborder="0"></iframe>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        url: "/sitemap.xml"
      };
    }
  };
  </script>
  
  <style>
  .sitemap {
    width: 100%;
    height: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
  </style>